<script setup lang="ts">
import { GBaseIcon, GPopover, useOutsideClick } from '@gem/uikit';
import { computed, inject, ref, watch } from 'vue';
import { createInitVal, createNumber, initialValue, spacing } from './commons';
import { MouseUpKey, StateListKey } from './keys';
import type { PaddingType, Spacing, SpacingOption, SpacingType } from './types';

const props = withDefaults(
  defineProps<{
    isFocus?: boolean;
    label?: string;
    property: keyof PaddingType;
    value?: string;
    diffValue?: string;
    inside?: boolean;
    placeholder?: string;
    align?: 'left' | 'right';
    options: SpacingOption[];
    min?: number;
    controlChange?: (id: keyof PaddingType, value?: any) => void;
    type?: 'normal' | 'small';
  }>(),
  {
    align: 'left',
  },
);

const emit = defineEmits<{
  (e: 'controlOnChange', property: keyof Spacing, value?: PaddingType): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'selectSpacing', property: keyof Spacing, isShow: boolean): void;
}>();
const inputEl = ref<HTMLInputElement>();
const { changeStateListOnFocus } = inject(StateListKey, {
  stateList: ref({}),
  changeStateListOnFocus: () => {},
});
const { mouseUp } = inject(MouseUpKey, { mouseUp: ref({}), detectMouseUp: () => {} });
const dom = ref<HTMLDivElement>();
const focus = ref(false);
const isSettingGlobalValue = ref(false);

const getValueUnit = () => {
  const { value } = props;
  let result;
  if (value === 'auto' || !value) {
    result = 'px';
  } else {
    const regex = new RegExp(/(\d*\.?\d+)\s?(px|em|ex|%|in|cn|mm|pt|pc+)/g);
    result = regex.test(value) ? value.replace(/(-|\d)+/gi, '') : 'px';
  }
  return result;
};

const iniUnit = computed(() => getValueUnit());
const position = computed(() => {
  return props.property.split('-')[1];
});

const valueGlobal = computed(() => props.options.find((item) => item.id === val.value));
const val = ref(createInitVal(props.value)); // initial value
const prevVal = ref(createInitVal(props.value));
const displayValue = computed(() => createNumber(val.value, valueGlobal.value));

watch(
  () => props.diffValue,
  (finalVal) => {
    let result;
    const { property, value } = props;
    result = parseInt((finalVal || 0) as string);

    if (isNaN(result)) {
      result = 0;
    }

    if (typeof props.min === 'number' && result < props.min) {
      result = props.min;
    }

    const final = `${result}`;
    if (value !== final) {
      val.value = final;
      emit('controlOnChange', property, final + iniUnit.value);
      prevVal.value = val.value;
    }
  },
);

watch(
  () => props.value,
  (newVal) => {
    if (!newVal) {
      val.value = undefined;
    } else if (newVal === 'auto') {
      val.value = 'auto';
    } else if (isNaN(parseInt(newVal))) {
      val.value = initialValue(newVal);
    } else {
      val.value = replaceStringToDigits(newVal);
    }
    prevVal.value = val.value;
  },
);

const replaceStringToDigits = (value: string) => {
  if (props.label === 'padding') return value.replace(/\D/g, '');
  return value.replace(/(^-?\d*\.?\d+)([^0-9]+)/g, '$1');
};

const handleOnChange = () => {
  emit(
    'controlOnChange',
    props.property,
    val.value ? (val.value >= 0 ? parseInt(val.value) : val.value) + iniUnit.value : undefined,
  );
};

const handleChange = () => {
  const DEFAULT_VALUE = '0px';
  if (!val.value) {
    props.controlChange?.(props.property, DEFAULT_VALUE);
    val.value = '0';
  } else if (val.value === 'auto') {
    props.controlChange?.(props.property, 'auto');
  } else if (isNaN(parseInt(val.value))) {
    if (spacing[val.value as keyof SpacingType]) {
      props.controlChange?.(props.property, 'var(' + spacing[val.value as keyof SpacingType] + ')');
    } else {
      props.controlChange?.(props.property, prevVal.value);
    }
  } else {
    val.value = replaceStringToDigits(parseInt(val.value).toString());
    prevVal.value = val.value;
    props.controlChange?.(props.property, replaceStringToDigits(val.value) + iniUnit.value);
  }
};

const chooseSpacingGlobal = (value: string) => {
  isSettingGlobalValue.value = true;
  val.value = value;
  prevVal.value = val.value;
  if (val.value === 'auto') {
    props.controlChange?.(props.property, 'auto');
  } else {
    const globalValue = 'var(' + spacing[val.value as keyof SpacingType] + ')';
    props.controlChange?.(props.property, globalValue);
    isSettingGlobalValue.value = false;
  }
};

const onClickSubAction = (type: string, value?: any) => {
  emit('onClickSubAction', type, value);
};

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  val.value = target?.value;
  handleOnChange();
};

const onOpenSelectSpacing = () => {
  emit('selectSpacing', props.property, true);
};

const onCloseSelectSpacing = () => {
  emit('selectSpacing', props.property, false);
};

const onFocus = () => {
  focus.value = true;
  changeStateListOnFocus(props.property);
};

const onBlur = () => {
  focus.value = false;
  handleChange();
};

useOutsideClick(
  dom,
  () => {
    if (val.value !== prevVal.value) {
      handleChange();
    }
  },
  { detectIframe: true },
);
watch(
  () => props.isFocus,
  (value) => {
    focus.value = value;
    if (value) {
      inputEl.value?.focus();
      /*Add delay to fix safari select
      https://stackoverflow.com/questions/6201278/how-to-select-all-text-in-textarea-on-focus-in-safari */
      setTimeout(() => inputEl.value?.select(), 10);
    } else {
      inputEl.value?.blur();
    }
  },
);

watch(
  () => mouseUp.value[props.property],
  () => {
    const { value } = props;
    const compareValue = val.value === '' ? '0' : val.value;
    const initialPropsValue = createInitVal(value);
    if (initialPropsValue != compareValue) {
      handleChange();
    }
  },
);
</script>
<template>
  <g-popover
    cls="!p-0"
    :has-arrow="false"
    :closeable="true"
    :overlay="false"
    overlay-container="#root-modal"
    :padding-right="23"
    placement="bottom"
    @close="onCloseSelectSpacing"
    @open="onOpenSelectSpacing">
    <template #default="{ open, close }">
      <div
        class="rounded-medium"
        :class="{
          '!border-primary-300': focus,
          top: position === 'top',
          left: position === 'left',
          bottom: position === 'bottom',
          right: position === 'right',
          inside: inside,
        }">
        <div ref="dom" class="gemx-dragger rounded-medium cursor-row-resize">
          <div
            class="group relative flex h-[32px] w-[50px] items-center justify-center rounded-xl border border-transparent px-4 hover:cursor-pointer"
            :class="{
              'border-primary-300 bg-dark-400': focus,
              'hover:bg-dark-150': !focus,
              '!w-[40px]': ['left', 'right'].includes(position),
              'h-[20px] w-[40px]': type === 'small',
            }">
            <input
              ref="inputEl"
              :value="displayValue"
              type="number"
              :title="val"
              class="text-12 font-regular placeholder:text-12 text-light-450 placeholder:text-text-dark-100 peer h-full w-full rounded-xl bg-transparent text-center leading-none outline-none"
              @input="
                (e) => {
                  handleInput(e);
                  !!open && close();
                }
              "
              @focus.stop="onFocus"
              @blur="onBlur"
              @onChange.stop="handleOnChange" />
          </div>
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <div
        data-test="editor-control-spacing-list"
        class="bg-dark-400 shadow-4dp rounded-12 absolute w-[132px] p-4 transition-all">
        <perfect-scrollbar class="h-[125px]">
          <div
            v-for="opt of options"
            :key="opt.id"
            data-test="editor-control-spacing-option"
            class="text-12 font-regular hover:bg-light-100/20 text-light-100 relative flex w-full cursor-pointer items-center truncate whitespace-nowrap rounded-xl py-8 pl-24 pr-10 leading-[14px]"
            @click="
              () => {
                chooseSpacingGlobal(opt.id);
                close();
              }
            ">
            <span v-if="val === opt.id" class="text-light-450 absolute inset-y-0 left-4 flex items-center">
              <GBaseIcon name="status-check" width="16" height="16" />
            </span>
            <p class="flex w-full items-center justify-between truncate">
              <span class="truncate">{{ opt.name }}</span>
              <span class="text-text-dark-300">{{ opt.value }}</span>
            </p>
          </div>
          <p
            class="text-12 text-primary-200 border-light-500/[0.15] cursor-pointer border-t pt-8 pb-4 text-center font-medium leading-[18px]"
            @click.stop="onClickSubAction('editGlobalStyle', 'gs-spacing')"
            @mousedown.stop="onClickSubAction('editGlobalStyle', 'gs-spacing')">
            Edit global style
          </p>
        </perfect-scrollbar>
      </div>
    </template>
  </g-popover>
</template>

<style lang="scss" scoped>
input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}
</style>
